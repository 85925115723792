<template>
    <v-card
       elevation="10"
       class="mx-auto"
   >
       <v-toolbar
           dark
           cards                
           flat
           outlined
           color="primary"
       >
           <v-card-title class="text-h6 font-weight-regular">
           {{cmpOptions.title}}
           </v-card-title>


           <v-spacer> </v-spacer>
 
           <v-btn 
               @click= "close($event)"
               icon>
               <v-icon>mdi-close-thick</v-icon>
           </v-btn>

       </v-toolbar>
       <v-divider></v-divider>
       <v-container>
               <v-form
                   ref="form"
                   v-model="form"
                   @submit.prevent="submit"
                   class="pa-4 pt-6"
               >
                   <v-alert type="error" v-if="errMessage != ''">
                   {{errMessage}}
                   </v-alert>
                       <v-text-field
                           v-model="editItem.firstname"
                           :rules="[rules.required]"
                           label="Vorname"
                           style="min-height: 96px">
                       </v-text-field>
                       <v-text-field
                           v-model="editItem.lastname"
                           :rules="[rules.required]"
                           label="Nachname"
                           style="min-height: 96px">
                       </v-text-field>

                       <v-autocomplete
                        v-if="!cmpOptions.isEdit"
                        v-model="selectedItems"
                        :items="availableCompanies"
                        item-text="name"
                        item-value="id"
                        :menu-props="{closeOnContentClick:true}"
                        chips
                        multiple
                        label="Betriebe"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="removeItem(data.item)"
                            >
                            <v-icon class="mr-1">
                                mdi-contacts-outline
                            </v-icon>
                            {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template 
                            v-slot:item="data"
                        >
                            <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-icon class="mr-4">
                                    mdi-contacts-outline
                                </v-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.town"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                       

                       <v-text-field
                           v-model="editItem.street"
                           label="Straße"
                           style="min-height: 96px">
                       </v-text-field>
                       <v-text-field
                           v-model="editItem.zip_code"
                           label="PLZ"
                           style="min-height: 96px">
                       </v-text-field>
                       <v-text-field
                           v-model="editItem.town"
                           label="Stadt"
                           style="min-height: 96px">
                       </v-text-field>
                       <v-text-field
                           v-model="editItem.email"
                           label="Email"
                           style="min-height: 96px">
                       </v-text-field>
                       <v-text-field
                           v-model="editItem.phone"
                           label="Telefon"
                           style="min-height: 96px">
                       </v-text-field>
                       <v-text-field
                           v-model="editItem.mobil"
                           label="Mobil"
                           style="min-height: 96px">
                       </v-text-field>
                  <v-divider></v-divider>
                   <v-card-actions>
                   <v-btn
                       
                       outlined
                       @click="$refs.form.reset()"
                   >
                       Reset
                   </v-btn>
                   <v-spacer></v-spacer>
                   <v-btn
                       :disabled="!form"
                       :loading="isLoading"
                       dark
                       color="primary"
                       depressed
                       type="submit"              
                   >
                       Speichern
                   </v-btn>
               </v-card-actions>
           </v-form>
       </v-container>
   </v-card>
</template>

<script>
   export default {
 

       props:{
           item: {
               required: false,
               type: Object,
               default: () => undefined
           },
           options:{
               required: false,
               type: Object
           },
           isVisible:{                
               required: true,
               default: false,
               type: Boolean
           }
       },

       data() {
           return {
               defaultOptions:{
                   title: 'Title',
                   isEdit: true
               },
               defaultItem: {
                    id:"",
                    firstname: "",
                    lastname: "",
                    street: "",
                    town: "",
                    zip_code: "",
                    email:"",
                    phone:"",
                    mobil:"",
                    enabled: true,
               },
               editItem: {
                    id:"",
                    firstname: "",
                    lastname: "",
                    street: "",
                    town: "",
                    zip_code: "",
                    email:"",
                    phone:"",
                    mobil:"",
                    enabled: true,
               },

               rules: {
                   required: v => !!v || 'This field is required'
               }, 

               selectedItems: [],  
               isLoading: false,
               message: '',
               errMessage: '',
               form: false,

           }
       },

       computed:{            

           currentUser() {
               return this.$store.state.auth.user;
            },


            availableCompanies: function() {
                    return this.currentUser.linkedCompanies;
            },

           cmpOptions(){   
               return {...this.defaultOptions,...this.options}
           },

           captionBtnSubmit(){
               return this.cmpOptions.isEdit ? 'Ändern' : 'Speichern';
           },


       },

       methods:{ 


            close(){
               console.debug('close')
               this.$emit('close')  
               this.$emit('update:isVisible', false)        
            },

            submit(){               
               console.debug('submit', this.editItem)
               if(!this.cmpOptions.isEdit && this.selectedItems.length == 0){
                            this.errMessage = 'Bitte einen Betrieb hinzufügen'
                            return;
                }

                if(!this.cmpOptions.isEdit)
                    this.editItem.companies = this.selectedItems

               const _this = this
               const apicmd = this.cmpOptions.isEdit ? "putApiSetStore" : "postApiSetStore"
               this.$store.dispatch('management/' + apicmd, {path:"api/management/contact", param:{ store:"contacts", data:this.editItem}})
               .then((response)=>{ 
                   console.log("submit:", response); 
                   this.$emit('close')  

               })
               .catch((error)=>{
                   _this.errMessage = error
                   console.error(error);
               })   
            },
           
           
            removeItem(item) {
                console.debug('removeItem', item)
                const index = this.selectedItems.indexOf(item.id)
                if (index >= 0) this.selectedItems.splice(index, 1)
            },



       },
       watch: { 
           isVisible: function(newVal) { // watch it
               this.errMessage  = '';
               console.debug('isVisible');
               if(newVal){
                   this.$refs.form.reset();
                   if(this.cmpOptions.isEdit){
                       console.debug('isVisible', this.item);
                       this.editItem = JSON.parse(JSON.stringify(this.item));
                   }
                   else{
                       
                       this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
                   }
               }
               else{
                   this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
                   this.selectedRoleId = undefined;
               }

               console.log('Prop changed: isVisible', newVal, ' | user: ', this.editItem)
           }
       },
       mounted() {
           this.errMessage  = '';
           console.debug('mounted');
           if(this.cmpOptions.isEdit){
               console.debug('isVisible', this.item);
               this.editItem = JSON.parse(JSON.stringify(this.item));
           }
           else{
               
               this.editItem = JSON.parse(JSON.stringify(this.defaultItem));
           }
       }

   }

</script>